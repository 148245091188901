.table-inbox {
    thead > tr > th,
    tbody > tr > td {
        font-size : .875rem !important;
    }
    tbody tr td, thead tr th {
        border : 2px solid #dedede;
    }    
    thead > tr > th {
        font-weight: bold;
    }
}