.btn-edit {
    padding: 0 5px !important;
    font-size: 11px !important;
}

.sidebar .nav-dropdown-items .nav-item{
    margin-left: 15px;
}

.form-group-retur {
    margin-bottom: 1px !important;
}

.alert-absolute{
    position: fixed;
    right: 10px;
    top: 20px;
    z-index: 9999;
    display: flex;
    flex-direction: row;
    align-items: center;
    /* background-color: #fee2e1; */
    background-color: rgba(254, 226, 225, 0.9);
    border-color: #fdd6d6;
    border-radius: 0.25rem;
    width: 300px;
}

.alert-absolute.success{
    position: fixed;
    right: 10px;
    top: 20px;
    z-index: 9999;
    display: flex;
    flex-direction: row;
    align-items: center;
    /* background-color: #fee2e1; */
    background-color: rgba(209, 231, 221, 0.9);
    border-color: #badbcc;
    border-radius: 0.25rem;
    width: 300px;
}

.form-check-input {
    margin-left: 0 !important;
}

.form-check {
    padding-left: 0 !important;
}

.form-check > .form-check-label {
    margin-left : 20px !important;
}

.alert-absolute:hover{
    background-color: rgb(254, 226, 225);
}

.alert-absolute .alert-text {
    flex: 4;
    color: #813838;        
    padding: 0.75rem 1.25rem;
    /* max-width: 3000px; */
}
.alert-absolute.success .alert-text {
    flex: 4;
    color: #0f5132;        
    padding: 0.75rem 1.25rem;
    /* max-width: 3000px; */
}

.alert-absolute .close-button {
    flex : 1;    
    padding: 0 10px;
}


.client-detail .foto-box {
    width: 65px;
    height: 65px;
    border-radius: 35px;
    background-color: #099c1c;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color : #fff;
    font-weight: bold;
    font-size: 16px;
}

.client-detail .status-text{
    font-size: 12px;
    font-style: italic;
}

.client-detail .status-text.Daftar{
    color: #909090;
}

.client-detail .status-text.Proses{
    color: #f5610b;
}

.client-detail .status-text.Aktif{
    color: #00a816;
}

.client-detail .detail-konsumen {
    font-size: 12px;
    color : #909090;
}

.client-detail .detail-konsumen .row {
    margin-top: 5px;    
}

.client-detail .card-title {
    font-size: 14px;
    border-bottom: 1px solid #cecece;
    padding-bottom: 10px;
    font-weight: 700;
}

.table-center thead tr th{
    text-align: center;
}

.table-small thead tr th,
.table-small tbody tr td,
.table-small tbody tr td input,
.table-small tbody tr td select{
    font-size: 12px;    
}

.table-small thead tr th,
.table-extra-small thead tr th {
    vertical-align: middle;
}

.table-extra-small thead tr th,
.table-extra-small tbody tr td,
.table-extra-small tbody tr td input,
.table-extra-small tbody tr td select{
    font-size: 10px;
}

.tb-jurnal tbody tr td {
    font-size: 12px;    
}

.box-akun-detail .form-group{
    margin-bottom: 0 !important;
}

.marginBottom0 { 
    margin-bottom: 0 !important;
}

.custom-flex-row {
    margin-top: -25px;
    margin-left: -30px !important;
    margin-right: -30px !important;
}

.form-group.pembayaran-ref-form {
    margin-bottom: 3px !important;
}

.table-header-withbackground thead tr th {
    background-color: #133279 !important;
    color: #FFFFFF;
}

.table tbody tr.tr-bold td {
    font-weight: bold;
}

.custom-container {
    width: 100% !important;
    max-width: 100% !important;
    padding-right: 0 !important;
    padding-left: 0 !important;
}

.container-pos {
    padding: 20px;
    background-color: #f5f5f5;
}
.custom-container {
    font-size: 12px !important;
}

.custom-container .form-control,
.custom-container label {
    font-size: 12px !important;
}

.row-penerima {
    background-color: #fff;
    padding-top: 10px;
    margin-top: 20px;
}

.table-permissions tbody tr.permission-active td {
    background-color: #4578de;
    color : #fff
}

.nav-link {
    font-size: 12px !important;
}


.sidebar {
    background-color: #3c4b64 !important;
}

.sidebar .nav-link.active {
    background-color:     rgba(255, 255, 255, 0.05) !important;
}

.table-header-withbackground thead tr th {
    background-color: #133279 !important;
    color: #FFFFFF;
}

.table-small thead tr th,
.table-small tbody tr td{
    font-size: 12px;
}

.table-center thead tr th {
    text-align: center;
    vertical-align: middle;
}

.table-vertical-center thead tr th {
    vertical-align: middle;
}

.foto-container {
    margin-top: 30px;     
}

.foto-container img {
    border-radius: 8px;
    border: 1px solid #cecece;
    max-height: 120px;
    width: auto;    
}

.table.table-without-padding-vertical tbody tr td { 
    padding: 0 !important;
    border : none;
}

.card-header {
    background-color: #fff !important;
    font-weight: 600 !important;
}

body { 
    font-family: Poppins,Roboto,Helvetica Neue,Arial,sans-serif !important;
}


.custom-tooltip .tooltip-inner {
    background-color: #3498db !important; /* Ganti dengan warna yang Anda inginkan */
    color: #ffffff; /* Warna teks */
  }
  
  .custom-tooltip .arrow::before {
    border-top-color: #3498db !important; /* Sesuaikan dengan warna background */
  }