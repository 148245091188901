.rekap-gaji-container {
    background-color: white;
    width: 29cm;
    min-height: 21cm; 
    padding: .5cm;
    .table {
        tbody tr td,
        thead tr th {
            font-size: 11px !important;
        }
    }
}